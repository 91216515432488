exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-cc-index-js": () => import("./../../../src/pages/cc/index.js" /* webpackChunkName: "component---src-pages-cc-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-features-asset-register-js": () => import("./../../../src/pages/features/asset-register.js" /* webpackChunkName: "component---src-pages-features-asset-register-js" */),
  "component---src-pages-features-asset-tagging-js": () => import("./../../../src/pages/features/asset-tagging.js" /* webpackChunkName: "component---src-pages-features-asset-tagging-js" */),
  "component---src-pages-features-inventories-js": () => import("./../../../src/pages/features/inventories.js" /* webpackChunkName: "component---src-pages-features-inventories-js" */),
  "component---src-pages-features-investments-js": () => import("./../../../src/pages/features/investments.js" /* webpackChunkName: "component---src-pages-features-investments-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-features-maintenance-js": () => import("./../../../src/pages/features/maintenance.js" /* webpackChunkName: "component---src-pages-features-maintenance-js" */),
  "component---src-pages-features-reservations-js": () => import("./../../../src/pages/features/reservations.js" /* webpackChunkName: "component---src-pages-features-reservations-js" */),
  "component---src-pages-features-tool-room-management-js": () => import("./../../../src/pages/features/tool-room-management.js" /* webpackChunkName: "component---src-pages-features-tool-room-management-js" */),
  "component---src-pages-fi-404-js": () => import("./../../../src/pages/fi/404.js" /* webpackChunkName: "component---src-pages-fi-404-js" */),
  "component---src-pages-fi-about-us-js": () => import("./../../../src/pages/fi/about-us.js" /* webpackChunkName: "component---src-pages-fi-about-us-js" */),
  "component---src-pages-fi-alihankkijalista-js": () => import("./../../../src/pages/fi/alihankkijalista.js" /* webpackChunkName: "component---src-pages-fi-alihankkijalista-js" */),
  "component---src-pages-fi-blog-js": () => import("./../../../src/pages/fi/blog.js" /* webpackChunkName: "component---src-pages-fi-blog-js" */),
  "component---src-pages-fi-case-studies-js": () => import("./../../../src/pages/fi/case-studies.js" /* webpackChunkName: "component---src-pages-fi-case-studies-js" */),
  "component---src-pages-fi-contact-js": () => import("./../../../src/pages/fi/contact.js" /* webpackChunkName: "component---src-pages-fi-contact-js" */),
  "component---src-pages-fi-features-asset-register-js": () => import("./../../../src/pages/fi/features/asset-register.js" /* webpackChunkName: "component---src-pages-fi-features-asset-register-js" */),
  "component---src-pages-fi-features-asset-tagging-js": () => import("./../../../src/pages/fi/features/asset-tagging.js" /* webpackChunkName: "component---src-pages-fi-features-asset-tagging-js" */),
  "component---src-pages-fi-features-inventories-js": () => import("./../../../src/pages/fi/features/inventories.js" /* webpackChunkName: "component---src-pages-fi-features-inventories-js" */),
  "component---src-pages-fi-features-investments-js": () => import("./../../../src/pages/fi/features/investments.js" /* webpackChunkName: "component---src-pages-fi-features-investments-js" */),
  "component---src-pages-fi-features-js": () => import("./../../../src/pages/fi/features.js" /* webpackChunkName: "component---src-pages-fi-features-js" */),
  "component---src-pages-fi-features-maintenance-js": () => import("./../../../src/pages/fi/features/maintenance.js" /* webpackChunkName: "component---src-pages-fi-features-maintenance-js" */),
  "component---src-pages-fi-features-reservations-js": () => import("./../../../src/pages/fi/features/reservations.js" /* webpackChunkName: "component---src-pages-fi-features-reservations-js" */),
  "component---src-pages-fi-features-tool-room-management-js": () => import("./../../../src/pages/fi/features/tool-room-management.js" /* webpackChunkName: "component---src-pages-fi-features-tool-room-management-js" */),
  "component---src-pages-fi-index-js": () => import("./../../../src/pages/fi/index.js" /* webpackChunkName: "component---src-pages-fi-index-js" */),
  "component---src-pages-fi-privacy-policy-js": () => import("./../../../src/pages/fi/privacy-policy.js" /* webpackChunkName: "component---src-pages-fi-privacy-policy-js" */),
  "component---src-pages-fi-research-js": () => import("./../../../src/pages/fi/research.js" /* webpackChunkName: "component---src-pages-fi-research-js" */),
  "component---src-pages-fi-terms-of-service-js": () => import("./../../../src/pages/fi/terms-of-service.js" /* webpackChunkName: "component---src-pages-fi-terms-of-service-js" */),
  "component---src-pages-ic-index-js": () => import("./../../../src/pages/ic/index.js" /* webpackChunkName: "component---src-pages-ic-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-r-index-js": () => import("./../../../src/pages/r/index.js" /* webpackChunkName: "component---src-pages-r-index-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-se-js": () => import("./../../../src/pages/se.js" /* webpackChunkName: "component---src-pages-se-js" */),
  "component---src-pages-seasonsgreetings-index-js": () => import("./../../../src/pages/seasonsgreetings/index.js" /* webpackChunkName: "component---src-pages-seasonsgreetings-index-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-xmas-competition-js": () => import("./../../../src/pages/xmas/competition.js" /* webpackChunkName: "component---src-pages-xmas-competition-js" */),
  "component---src-pages-xmas-index-js": () => import("./../../../src/pages/xmas/index.js" /* webpackChunkName: "component---src-pages-xmas-index-js" */),
  "component---src-templates-en-blog-post-js": () => import("./../../../src/templates/en/blog-post.js" /* webpackChunkName: "component---src-templates-en-blog-post-js" */),
  "component---src-templates-en-case-study-js": () => import("./../../../src/templates/en/case-study.js" /* webpackChunkName: "component---src-templates-en-case-study-js" */),
  "component---src-templates-fi-blog-post-js": () => import("./../../../src/templates/fi/blog-post.js" /* webpackChunkName: "component---src-templates-fi-blog-post-js" */),
  "component---src-templates-fi-case-study-js": () => import("./../../../src/templates/fi/case-study.js" /* webpackChunkName: "component---src-templates-fi-case-study-js" */)
}

